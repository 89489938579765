"use client";

import React, { useRef } from "react";
import {
	Box,
	Typography,
	Container,
	Card,
	CardContent,
	Button,
} from "@mui/material";
import { motion, useScroll, useTransform } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import solution from "./images/solution.svg";
import network from "./images/2.png";
import business from "./images/1.png";
import delivery from "./images/close.png";
import content from "./images/3.png";
import { scrollToRegister } from "./utils/scrollUtils";
const painPoints = [
	{
		title: "Instant Quotes.",
		subtitle: "Guaranteed quotes genereated by AI",
		description:
			"Our AI is trained to provide the lowest quote available in the market. No big markups, no  uncertainity, leaving you more margins.",
		image: solution,
		btn: "Get a Free Quote",
	},
	{
		title: "Access to curated suppliers",
		subtitle: "We have already negotiated on behalf of You!",
		description:
			"Our platform has connections with hundreds of Curated suppliers in each category, ensuring quality and reliability.Since we have the volume advantage, we get the best price. So do You!",
		image: network,
		btn: "Explore How",
	},
	{
		title: "Competitive prices without MOQ",
		subtitle: "No longer need to worry about large markups",
		description:
			"Enjoy wholesale prices without minimum order quantity restrictions.We have pre-negotiated with suppliers.",
		image: business,
		btn: "Get a Free Quote",
	},
	{
		title: "Same-day deliveries",
		subtitle: "We Deliver Twice a Day, So that you  can service faster",
		description:
			"Experience predictable and fast delivery timelines for your urgent needs. You can now focus on service, without worrying about deliveries.",
		image: delivery,
		btn: "Connect with Us",
	},
	{
		title: "Context-sensitive repair content",
		subtitle: "Expertise of AI powered content for all service needs",
		description:
			"Access tailored repair information to support your service operations just when you need it. Experience the step by step service videos and images thus even inexperienced technician and mechanic can complete service reliably",
		image: content,
		btn: "Get Free Quote",
	},
];

const PainPointRow = ({ point, index }) => {
	const theme = useTheme();
	const rowRef = useRef(null);
	const { scrollYProgress } = useScroll({
		target: rowRef,
		offset: ["start end", "end start"],
	});
	const y = useTransform(scrollYProgress, [0, 1], [50, -50]);

	return (
		<Box
			ref={rowRef}
			sx={{
				display: "flex",
				flexDirection: {
					xs: "column",
					md: index % 2 === 0 ? "row-reverse" : "row",
				},
				alignItems: "center",
				mb: { xs: 6, md: 0 },
			}}
		>
			<motion.div
				initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
				animate={{ opacity: 1, x: 0 }}
				transition={{ duration: 1 }}
				style={{ flex: 1 }}
			>
				<Card
					elevation={0}
					sx={{
						bgcolor: "transparent",
						height: "100%",
					}}
				>
					<CardContent sx={{ p: 4 }}>
						<Typography
							variant='body2'
							component='body2'
							gutterBottom
							sx={{
								fontWeight: 600,
								color: "#121212",
							}}
						>
							{point.title}
						</Typography>
						<Typography
							variant='h2'
							component='h2'
							gutterBottom
							sx={{
								fontWeight: 600,
								color: theme.palette.primary.main,
							}}
						>
							{point.subtitle}
						</Typography>
						<Typography variant='body1'>{point.description}</Typography>
						<Box sx={{ margin: 2 }}></Box>
						<Button variant='contained' size='large' onClick={scrollToRegister}>
							{point.btn}
						</Button>
					</CardContent>
				</Card>
			</motion.div>

			<motion.div
				style={{ y, flex: 1 }}
				initial={{ opacity: 0, x: index % 2 === 0 ? 50 : -50 }}
				animate={{ opacity: 1, x: 0 }}
				transition={{ duration: 0.8 }}
			>
				<Box
					sx={{
						position: "relative",
						height: { xs: "250px", md: "600px" },
						width: "100%",
						// borderRadius: "24px",
						overflow: "hidden",
						// boxShadow: "rgba(0, 0, 0, 0.2) 0px 18px 50px -10px",
						// boxShadow: "0 20px 40px rgba(0,0,0,0.1)",
						my: { xs: 4, md: 0 },
					}}
				>
					<img
						src={point.image}
						alt={point.title}
						fill
						style={{
							objectFit: "cover",
						}}
						priority
					/>
				</Box>
			</motion.div>
		</Box>
	);
};

const PainPoints = () => {
	const theme = useTheme();

	return (
		<Box
			component='section'
			sx={{
				py: { xs: 8, md: 12 },
				bgcolor: "background.default",
			}}
		>
			<Container maxWidth='lg'>
				<Typography
					variant='h2'
					align='center'
					sx={{
						fontSize: { xs: "2.5rem", md: "3.5rem" },
						fontWeight: 700,
						mb: 8,
						background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
						WebkitBackgroundClip: "text",
						WebkitTextFillColor: "transparent",
					}}
				>
					Solving Core Service Center Problems
				</Typography>

				{painPoints.map((point, index) => (
					<PainPointRow key={index} point={point} index={index} />
				))}
			</Container>
		</Box>
	);
};

export default PainPoints;
