import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Container } from "@mui/material";
import theme from "./theme";
import Hero from "./Hero.js";
import PainPoints from "./PainPoints";
import RegistrationForm from "./RegistrationForm";
import Footer from "./Footer";
import AppBarComponent from "./components/Appbar.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Home() {
	const user = useSelector((state) => state.user);
	const navigate = useNavigate();
	useEffect(() => {
		if (user.loggedIn) {
			if (user.user?.user?.userType == "STORE_ADMIN") {
				navigate("/store-queries");
			} else if (user.user?.user?.userType == "MERCHANT_ADMIN") {
				navigate("/m-dashboard");
			} else if (user.user?.user?.userType == "GLOBAL") {
				navigate("/settlements/store");
			}
		}
	}, []);
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<AppBarComponent />

			<Hero />
			<PainPoints />
			<RegistrationForm />
			<Footer />
		</ThemeProvider>
	);
}

export default Home;
