import React from "react";
import { Box, Typography, Link, IconButton } from "@mui/material";
import { Facebook, Twitter, LinkedIn } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const Footer = () => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				bgcolor: theme.palette.secondary.main,
				color: "white",
				py: 4,
				textAlign: "center",
			}}
		>
			<Typography variant='body1' gutterBottom>
				Contact: admin@jumbuda.com | +971-55 880 2911{" "}
			</Typography>
			<Box sx={{ my: 2 }}>
				<IconButton color='inherit' aria-label='Facebook'>
					<Facebook />
				</IconButton>
				<IconButton color='inherit' aria-label='Twitter'>
					<Twitter />
				</IconButton>
				<IconButton color='inherit' aria-label='LinkedIn'>
					<LinkedIn />
				</IconButton>
			</Box>
			<Typography variant='body2'>
				© {new Date().getFullYear()} Jumbuda.com All rights reserved.
			</Typography>
		</Box>
	);
};

export default Footer;
