import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			main: "#C04848",
		},
		secondary: {
			main: "#480048",
		},
	},
	typography: {
		fontFamily: '"Rubik","Roboto", "Helvetica", "Arial", sans-serif',
		h1: {
			fontSize: "3rem",
			fontWeight: 700,
		},
		h2: {
			fontSize: "2.5rem",
			fontWeight: 600,
		},
		h3: {
			fontSize: "2rem",
			fontWeight: 600,
		},
	},
});

export default theme;
