import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import logo from "../images/logo.png";
import { useNavigate } from "react-router-dom";
const StyledAppBar = styled(AppBar)(({ theme, scrolled }) => ({
	background: scrolled ? "rgba(255, 255, 255, 0.9)" : "transparent",
	boxShadow: scrolled ? theme.shadows[4] : "none",
	transition: "background 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
}));

const AppBarComponent = () => {
	const [scrolled, setScrolled] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		const handleScroll = () => {
			const isScrolled = window.scrollY > 10;
			if (isScrolled !== scrolled) {
				setScrolled(isScrolled);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [scrolled]);

	return (
		<StyledAppBar position='fixed' scrolled={scrolled ? 1 : 0}>
			<Toolbar>
				<Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
					<img src={logo} alt='Jumbuda Logo' width={40} height={40} />
					<Typography
						variant='h6'
						component='div'
						sx={{
							ml: 2,
							fontWeight: 700,
							color: scrolled ? "text.primary" : "white",
						}}
					>
						Jumbuda
					</Typography>
				</Box>
				<Button
					color='inherit'
					variant='outlined'
					sx={{
						borderColor: scrolled ? "primary.main" : "white",
						color: scrolled ? "primary.main" : "white",
						"&:hover": {
							borderColor: scrolled ? "primary.dark" : "white",
							backgroundColor: scrolled
								? "rgba(0, 0, 0, 0.04)"
								: "rgba(255, 255, 255, 0.1)",
						},
					}}
					onClick={() => navigate("/login")}
				>
					Register / Login
				</Button>
			</Toolbar>
		</StyledAppBar>
	);
};

export default AppBarComponent;
