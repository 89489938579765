import { useSelector } from "react-redux";
import queries from "../../api/queries";
import React, { useEffect, useState } from "react";
import {
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	Checkbox,
	Container,
	Dialog,
	DialogContent,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	Paper,
	Snackbar,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import MKBreadCrumbs from "components/MKBreadCrumbs";
import { TbBrandAbstract, TbBrandRadixUi } from "react-icons/tb";
import _ from "lodash";
import MKTypography from "components/MKTypography";
import { AddShoppingCart, CheckCircle } from "@mui/icons-material";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MKSwipable from "components/MKSwipable";
import { DataGrid } from "@mui/x-data-grid";
import { CiCircleRemove, CiEdit, CiSaveUp1 } from "react-icons/ci";
import { VscSyncIgnored } from "react-icons/vsc";
import MKCountdown from "components/MKCountDown";
import { IoDocumentAttach } from "react-icons/io5";
import { GoGitMerge, GoPackage } from "react-icons/go";
import { IoIosRemoveCircleOutline, IoMdAdd } from "react-icons/io";
import { MdArrowCircleRight } from "react-icons/md";
import { MdOutlinePostAdd } from "react-icons/md";
import { PiPackageThin } from "react-icons/pi";
import { CiSquareRemove } from "react-icons/ci";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";

const breadcrumb = [
	{ id: 1, name: "Home", link: "/" },
	{ id: 2, name: "My Queries", link: "" },
];

const RowDetail = ({ queryText }) => {
	return (
		<div style={{ borderTop: "1px solid #ccc", width: "100%" }}>
			{queryText}
		</div>
	);
};

const MerchantQueries = (props) => {
	const location = useLocation();
	const [myQueries, setMyQueries] = useState([]);
	const merchant = useSelector((state) => state.merchants.merchant);
	const products = useSelector((state) => state.products.products);
	const brands = useSelector((state) => state.brands.brands);
	const models = useSelector((state) => state.models.models);
	const variants = useSelector((state) => state.variants.variants);
	const [expiredQueries, setExpiredQueries] = useState([]);
	const [activeTab, setTabValue] = useState(0);
	const [mechantQueryResponse, setMerchantQueryResponse] = useState([]);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [unitPrice, setUnitPrice] = useState({});
	const [unavailableUnits, setUnavailableUnits] = useState({});
	const [imageToShow, setImageToShow] = useState("");
	const [showImageDialog, setShowImageDialog] = useState(false);
	const [prices, setPrices] = useState({});
	const [variantsAdded, setVariantsAdded] = useState([]);
	const [expiry, setExpiry] = useState(300);
	const addVariants = (merchantQueryId) => {
		let current = [...variantsAdded];
		const exists = _.find(current, (v) => {
			return v.merchantQueryId == merchantQueryId;
		});
		const id = Math.floor(Math.random() * 1000 + 1);
		if (exists) {
			setVariantsAdded(current);
			const variant = {
				id: id,
				name: "Variant" + variantsAdded.length,
				price: 0,
				merchantQueryId: merchantQueryId,
				unavailable: false,
				canRemove: true,
				isSubmitted: false,
			};
			current.push(variant);
		} else {
			const variant = {
				id: id,
				name: "Original",
				price: 0,
				merchantQueryId: merchantQueryId,
				unavailable: false,
				canRemove: false,
				isSubmitted: false,
			};
			current.push(variant);
			setVariantsAdded(current);
		}
	};
	const removeVariant = (variantId) => {
		const found = _.find(variantsAdded, (v) => {
			return v.id == variantId;
		});
		if (found) {
			if (found.canRemove) {
				const remaining = _.filter(variantsAdded, (v) => {
					return v.id != variantId;
				});
				setVariantsAdded(remaining);
			} else {
				let updated = _.map(variantsAdded, (v) => {
					if (v.id == variantId) {
						v.unavailable = !v.unavailable;
					}
					return v;
				});
				setVariantsAdded(updated);
			}
		}
	};

	const handleSubmitV2 = (vid, query) => {
		const varnt = _.find(variantsAdded, (v) => {
			return v.id == vid;
		});

		const brandId = query.brand.id;
		const modelId = query.model.id;
		const variantName = varnt.name;
		const amount = Number(varnt.price);
		const productId = query.product.id;
		const queryId = query.productQueryId;
		const merchantQueryId = query.merchantQueryId;
		let unavailable = varnt.unavailable;
		const merchantId = query.merchantId;
		const queryGroupId = query.queryGroupId;
		if (amount <= 0) {
			if (variantName != "Original") {
				setOpen(true);
				setMessage("The Amount Must be Greater than Zero");
				return;
			} else {
				unavailable = true;
			}
		}
		const payload = {
			variantName: variantName,
			brandId: brandId,
			modelId: modelId,
			productId: productId,
			productQueryId: queryId,
			price: amount,
			merchantQueryId: merchantQueryId,
			unavailable: unavailable,
			merchantId: merchantId,
			queryGroupId: queryGroupId,
		};

		queries
			.submitMerchantQueryResponseV2(payload)
			.then((result) => {
				const remainingVariants = _.map(variantsAdded, (v) => {
					if (vid == v.id) {
						v.isSubmitted = true;
					}
					return v;
				});
				setVariantsAdded(remainingVariants);
			})
			.catch((err) => {
				console.log(err);
				setOpen(true);
				setMessage("FAILED! to submit quote");
			});
	};

	const handleDoneSubmittingAllVariants = (query) => {
		const remaining = _.filter(myQueries, (q) => {
			return q.merchantQueryId != query.merchantQueryId;
		});
		setMyQueries(remaining);
	};
	const handleVariantNameChange = (val, id) => {
		let current = [...variantsAdded];
		const next = _.map(current, (v) => {
			if (v.id == id) {
				const rec = { ...v, name: val };
				return rec;
			} else {
				return v;
			}
		});
		setVariantsAdded(next);
	};
	const handleVariantPriceChange = (val, id) => {
		let current = [...variantsAdded];
		const next = _.map(current, (v) => {
			if (v.id == id) {
				const rec = { ...v, price: val };
				return rec;
			} else {
				return v;
			}
		});
		setVariantsAdded(next);
	};

	const handleUnitPriceUpdate = (row, e) => {
		let currentUnitPrice = { ...unitPrice };
		currentUnitPrice[row.merchantQueryId] = e.target.value;
		setUnitPrice(currentUnitPrice);
	};
	const handleExpiry = (row) => {
		let current = [...expiredQueries];
		current.push(row.id);
		setExpiredQueries(current);
	};
	useEffect(() => {
		if (location.state) {
			const q = location.state.query;
			const e = location.state.expiry;
			setExpiry(e);
			let addedVariants = [
				{
					id: Math.floor(Math.random() * 1000 + 1),
					name: "Original",
					price: 0,
					merchantQueryId: q.merchantQueryId,
					unavailable: false,
					canRemove: false,
					isSubmitted: false,
				},
				{
					id: Math.floor(Math.random() * 1000 + 1),
					name: "Variant 1",
					price: 0,
					merchantQueryId: q.merchantQueryId,
					unavailable: false,
					canRemove: true,
					isSubmitted: false,
				},
			];
			setVariantsAdded(addedVariants);
			setMyQueries([q]);
		}
	}, []);

	const handleShowImage = (image) => {
		setImageToShow(image);
		setShowImageDialog(true);
	};

	return (
		<div>
			<MKBreadCrumbs crumbs={breadcrumb} />
			<Snackbar
				open={open}
				autoHideDuration={4000}
				onClose={() => setOpen(false)}
				message={
					<MKTypography variant='caption' color='white'>
						{message}
					</MKTypography>
				}
				action={
					<MKButton
						iconOnly
						color='white'
						circular
						size='small'
						onClick={() => setOpen(false)}
					>
						<CheckCircle />
					</MKButton>
				}
			/>
			<Dialog open={showImageDialog} onClose={() => setShowImageDialog(false)}>
				<DialogContent>
					<div
						style={{ width: "500px", height: "500px", objectFit: "contain" }}
					>
						<img src={imageToShow} width='100%' height='100%' />
					</div>
				</DialogContent>
			</Dialog>
			<Paper
				sx={{
					bgcolor: "#ffffff",
					alignItems: "center",
					justifyContent: "center",
					padding: 5,
					display: "flex",
					flexDirection: "column",
				}}
				elevation={0}
			>
				<Stack gap={3}>
					{myQueries.length ? (
						_.map(myQueries, (query) => {
							return (
								<Card sx={styles.card} elevation={0}>
									<CardHeader
										action={
											<Box
												sx={{
													alignItems: "center",
													justifyContent: "center",
													display: "flex",
												}}
											>
												<MKTypography color='error' variant='caption'>
													Expires in (Min):
													<MKCountdown
														value={expiry}
														createdAt={query.createdAt}
														onExpiry={() => handleExpiry(query)}
													/>
												</MKTypography>
												<MKButton
													onClick={() => handleDoneSubmittingAllVariants(query)}
												>
													Completed
													<IconButton>
														<IoCheckmarkDoneCircleOutline />
													</IconButton>
												</MKButton>
											</Box>
										}
									/>
									{query.image && (
										<CardMedia
											component='img'
											height='194'
											src={query.image}
											alt='Product image'
											onClick={() => handleShowImage(query.image)}
										></CardMedia>
									)}
									<CardHeader
										title={
											<Stack direction={"row"} gap={3}>
												<Paper
													sx={{
														width: 64,
														height: 64,
														borderRadius: 100,
														alignItems: "center",
														justifyContent: "center",
														display: "flex",
														borderColor: "#451752",
														backgroundColor: "#121212",
													}}
													elevation={0}
												>
													<PiPackageThin size={34} color='#ffffff' />
												</Paper>
												<Stack>
													<MKTypography variant='body1'>
														{query.brand.name} {query.model.name}
													</MKTypography>
													<MKTypography variant='body2'>
														{query.product.name}
													</MKTypography>
												</Stack>
											</Stack>
										}
										subheader={
											<Stack
												gap={3}
												direction={"row"}
												sx={{
													alignItems: "center",
													justifyContent: "flex-end",
												}}
											></Stack>
										}
										action={
											<Stack gap={1}>
												<Box
													style={{
														alignItems: "center",
														justifyContent: "flex-start",
														flexDirection: "row",
														display: "flex",
													}}
												>
													<Box
														style={{
															width: 15,
															height: 15,
															backgroundColor: query.color.hex,
															marginRight: 10,
														}}
													></Box>
													<MKTypography variant='caption'>
														{query.color?.name}
													</MKTypography>
												</Box>
												<MKTypography variant='caption' fontWeight='bold'>
													Qty: {query.quantity}
												</MKTypography>
											</Stack>
										}
									/>
									<CardContent
										sx={{
											p: 2,
											borderColor: "#121212",
											borderWidth: 1,
											borderRadius: 5,
											backgroundColor: "#f5f5f5",
										}}
									>
										<Box
											sx={{
												width: "100%",
												alignItems: "center",
												justifyContent: "flex-start",
												display: "flex",
												cursor: "pointer",
												flexDirection: "row",
											}}
										>
											<Stack
												direction='row'
												gap={1}
												sx={{
													alignItems: "center",
													justifyContent: "center",
													display: "flex",
													mb: 3,
													width: "100%",
												}}
											>
												<MKTypography
													variant='body2'
													fontSize='18'
													fontWeight='bold'
													style={{ flex: 1 }}
												>
													Add Available Variants & Prices
												</MKTypography>
												<MKButton
													variant='text'
													size='small'
													color='black'
													onClick={() => addVariants(query.merchantQueryId)}
												>
													Add a Variant +
												</MKButton>
											</Stack>
										</Box>
										{!unavailableUnits[query.id] ? (
											<Stack gap={1}>
												{_.map(
													_.filter(variantsAdded, (va) => {
														return va.merchantQueryId == query.merchantQueryId;
													}),
													(v) => {
														return (
															<Stack direction='row' gap={2}>
																<MKInput
																	placeholder='Name'
																	value={v.name}
																	label='Name'
																	disabled={
																		v.name == "Original" || v.isSubmitted
																	}
																	onChange={(e) =>
																		handleVariantNameChange(
																			e.target.value,
																			v.id
																		)
																	}
																/>

																<MKInput
																	placeholder='Price'
																	value={
																		v.unavailable ? "Unavailable" : v.price
																	}
																	label='Price'
																	variant={"outlined"}
																	onChange={(e) =>
																		handleVariantPriceChange(
																			e.target.value,
																			v.id
																		)
																	}
																	disabled={v.unavaiable || v.isSubmitted}
																	hintText='Unavailable'
																/>
																<Box sx={{ minWidth: 120 }}>
																	{v.isSubmitted ? (
																		<MKButton
																			variant='text'
																			size='small'
																			color='black'
																			disabled
																		>
																			Submitted
																		</MKButton>
																	) : (
																		<MKButton
																			variant='contained'
																			size='small'
																			color='black'
																			onClick={() =>
																				handleSubmitV2(v.id, query)
																			}
																		>
																			Submit
																		</MKButton>
																	)}
																</Box>

																<Box sx={{ minWidth: 180 }}>
																	{v.name == "Original" ? (
																		<MKButton
																			onClick={() => removeVariant(v.id)}
																			disabled={v.isSubmitted}
																			variant='text'
																			color='dark'
																		>
																			{v.unavailable ? (
																				<CiEdit />
																			) : (
																				<CiCircleRemove />
																			)}
																			&nbsp;
																			{v.unavailable
																				? "Edit Unit Price"
																				: "Mark Unavailable"}
																		</MKButton>
																	) : (
																		<MKButton
																			onClick={() => removeVariant(v.id)}
																			disabled={v.isSubmitted}
																			variant='text'
																			color='dark'
																		>
																			<IoIosRemoveCircleOutline /> Remove this
																			Variant
																		</MKButton>
																	)}
																</Box>

																{/* <IconButton
																onClick={() =>
																	addVariants(query.merchantQueryId)
																}
															>
																<MdOutlinePostAdd />
															</IconButton> */}
															</Stack>
														);
													}
												)}
											</Stack>
										) : (
											<MKButton
												variant='contained'
												color='black'
												fullWidth
												onClick={() => handleSubmitV2(null, query)}
											>
												Submit&nbsp;
												<MdArrowCircleRight size={24} />
											</MKButton>
										)}
									</CardContent>
								</Card>
							);
						})
					) : (
						<MKTypography>No outstanding Query!</MKTypography>
					)}
				</Stack>
			</Paper>
		</div>
	);
};

export default MerchantQueries;

const styles = {
	card: {
		minWidth: 700,
		p: 3,
		backgroundColor: "#fcfcfc",
		elevation: 0,
	},
	container: {
		minHeight: "100vh",
		width: "100%",
		padding: 2,
	},
	sideBar: {
		minHeight: "100vh",
		width: "100%",
		padding: 2,
		backgroundColor: "#FBFCFA",
	},
	filter: {
		minHeight: "5vh",
		width: "100%",
		padding: 2,
		backgroundColor: "#FCFCFC",
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		flexDirection: "row",
	},
	queryRow: {
		width: "100%",
		padding: 2,
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		flexDirection: "row",
		marginTop: 1,
		backgroundColor: "#FEFCFC",
	},
};
