import { ViewAgenda } from "@mui/icons-material";
import { Box, Container, IconButton, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Barcode, Package } from "@phosphor-icons/react";
import queries from "api/queries";
import queryRules from "../../api/query-rules";
import MKBreadCrumbs from "components/MKBreadCrumbs";
import MKCountdown from "components/MKCountDown";
import MKTypography from "components/MKTypography";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { BiLoader } from "react-icons/bi";
import { IoMdSend } from "react-icons/io";
import { PiBasket, PiSlackLogo } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const breadcrumb = [
	{ id: 1, name: "Home", link: "/" },
	{ id: 2, name: "My Queries", link: "" },
];
const MerchantQueryList = () => {
	const navigate = useNavigate();
	const [myQueries, setMyQueries] = useState([]);
	const merchant = useSelector((state) => state.merchants.merchant);
	const products = useSelector((state) => state.products.products);
	const brands = useSelector((state) => state.brands.brands);
	const models = useSelector((state) => state.models.models);
	const variants = useSelector((state) => state.variants.variants);
	const [loading, setLoading] = useState(false);
	const [expiryRule, setExpiryRule] = useState(300);
	const [queryToShow, setQueryToShow] = useState(null);

	useEffect(() => {
		queryRule();
		const interval = setInterval(getQueries, 10000);
		return () => clearInterval(interval);
	}, []);
	useEffect(() => {
		queryRule();
		getQueries();
	}, []);
	const queryRule = async () => {
		queryRules.getQueryExipryRule("query_expiry").then((result) => {
			const rule = result.rule;
			setExpiryRule(rule.rule.value);
		});
	};

	const getQueries = async () => {
		setLoading(true);
		queries
			.getMerchantQueries(merchant.id, "RESPONSE_AWAITING", 1, 100)
			.then((response) => {
				const { totalCount, merchantQueries } = response;
				const filtered = _.filter(merchantQueries, (m) => {
					return m.merchantQueryStatus != "QUERY_EXPIRED";
				});
				const ret = _.map(filtered, (query) => {
					return buildQuery(query);
				});
				setLoading(false);
				setMyQueries(ret);
			});
	};

	const buildQuery = (query) => {
		const productQuery = query.ProductQuery;
		console.log("Product Query:", query);
		// const brand = _.find(brands, (b) => {
		// 	return b.id == productQuery.brandId;
		// });
		// const model = _.find(models, (m) => {
		// 	return m.id == productQuery.modelsId;
		// });
		const brand = query.ProductQuery?.Brand;
		const model = query.ProductQuery?.Models;

		const quantity = productQuery.quantity;
		const color = productQuery.color;
		// const product = _.find(products, (p) => {
		// 	return p.id == productQuery.productId;
		// });
		const product = query.ProductQuery?.Products;
		const queryText = productQuery.queryText;

		let quality = "Original";
		if (productQuery.qualityVariant == "FIRST_COPY") {
			if (productQuery.variantId) {
				const variant = _.find(variants, (v) => {
					return v.id == productQuery.variantId;
				});
				quality = variant.name;
			} else {
				quality = "Other";
			}
		}

		const s = {
			id: query.id,
			image: productQuery.image,
			merchantQueryId: query.id,
			productQueryId: query.productQueryId,
			merchantId: query.merchantId,
			queryGroupId: query.queryGroupId,
			queryGroupName: query.queryGroupName,
			brand: brand,
			model: model,
			quality: quality,
			product: product,
			quantity: quantity,
			queryText: queryText,
			color: color,
			createdAt: productQuery.createdAt,
		};
		return s;
	};
	const handleExpiry = () => {};
	const handleViewQuery = (row) => {
		navigate("/merchant-query", {
			state: { query: row, expiry: expiryRule },
		});
	};

	const columns = [
		{
			field: "id",
			headerName: "#",
			minwidth: 150,
			renderCell: (params) => {
				return (
					<MKTypography color='dark' variant='body2'>
						{params.row?.id}
					</MKTypography>
				);
			},
		},
		{
			field: "brand",
			headerName: (
				<MKTypography
					variant='body2'
					color='dark'
					textGradient
					style={{ alignItems: "center", display: "flex" }}
				>
					<Package /> Brand
				</MKTypography>
			),
			minwidth: 150,
			flex: 1,
			renderCell: (params) => {
				return (
					<MKTypography variant='body2' color='dark' textGradient>
						{params.row.brand?.name}
					</MKTypography>
				);
			},
		},
		{
			field: "model",
			headerName: (
				<MKTypography
					variant='body2'
					color='dark'
					textGradient
					style={{ alignItems: "center", display: "flex" }}
				>
					<Barcode /> Model
				</MKTypography>
			),
			flex: 1,

			renderCell: (params) => {
				return (
					<MKTypography variant='body2' color='dark'>
						{params.row.model?.name}
					</MKTypography>
				);
			},
		},
		{
			field: "product",
			headerName: (
				<MKTypography
					variant='body2'
					color='dark'
					textGradient
					style={{ alignItems: "center", display: "flex" }}
				>
					<PiBasket /> Product
				</MKTypography>
			),
			flex: 1,

			renderCell: (params) => {
				return (
					<MKTypography variant='body2' color='dark'>
						{params.row.product?.name}
					</MKTypography>
				);
			},
		},
		{
			field: "expity",
			headerName: (
				<MKTypography
					variant='body2'
					color='dark'
					textGradient
					style={{ alignItems: "center", display: "flex" }}
				>
					<PiBasket /> Expires In
				</MKTypography>
			),
			flex: 1,

			renderCell: (params) => {
				return (
					<MKTypography variant='body2' color='dark'>
						<MKCountdown
							createdAt={params.row.createdAt}
							value={expiryRule}
							onExpiry={() => handleExpiry()}
						/>
					</MKTypography>
				);
			},
		},
		{
			field: "action",
			headerName: (
				<MKTypography
					variant='body2'
					color='dark'
					textGradient
					style={{ alignItems: "center", display: "flex" }}
				>
					<PiSlackLogo /> View
				</MKTypography>
			),
			renderCell: (params) => {
				return (
					<IconButton onClick={() => handleViewQuery(params.row)}>
						<IoMdSend />
					</IconButton>
				);
			},
		},
	];

	return (
		<Container maxWidth='md'>
			<MKBreadCrumbs crumbs={breadcrumb} />

			{myQueries.length ? (
				<Paper>
					<div style={{ width: "100%" }}>
						<DataGrid rows={myQueries} columns={columns} />
					</div>
				</Paper>
			) : (
				<Box
					sx={{
						alignItems: "center",
						display: "flex",
						justifyContent: "center",
						minHeight: "40vh",
					}}
				>
					<MKTypography
						variant='body2'
						style={{ fontSize: 34, fontFamily: "Lato" }}
					>
						No Outstanding Queries
					</MKTypography>
				</Box>
			)}
		</Container>
	);
};
export default MerchantQueryList;
