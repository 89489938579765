import React, { useState } from "react";
import {
	Box,
	Typography,
	TextField,
	Button,
	Grid,
	Container,
} from "@mui/material";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useNavigate } from "react-router-dom";

const RegistrationForm = () => {
	const theme = useTheme();
	const [phone, setPhone] = useState("");
	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		// Handle form submission here
		navigate("/login");
	};

	return (
		<Box
			id='register-now'
			component='section'
			sx={{
				py: { xs: 8, md: 12 },
				bgcolor: "background.default",
			}}
		>
			<Container maxWidth='md'>
				<motion.div
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.5 }}
				>
					<Typography
						variant='h2'
						align='center'
						sx={{
							mb: 4,
							fontSize: { xs: "2.5rem", md: "3.5rem" },
							fontWeight: 700,
							background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
							WebkitBackgroundClip: "text",
							WebkitTextFillColor: "transparent",
						}}
					>
						Register Now
					</Typography>
					<Box
						component='form'
						onSubmit={handleSubmit}
						sx={{
							mt: 4,
							p: 4,
							bgcolor: "background.paper",
							borderRadius: 2,
							boxShadow: 3,
						}}
					>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={6}>
								<TextField fullWidth label='Name' variant='outlined' required />
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label='Email'
									type='email'
									variant='outlined'
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<PhoneInput
									country={"ae"}
									value={phone}
									onChange={setPhone}
									inputStyle={{
										width: "100%",
										height: "56px",
										fontSize: "1rem",
										paddingLeft: "48px",
									}}
									buttonStyle={{
										backgroundColor: "transparent",
										border: `1px solid ${theme.palette.divider}`,
										borderRight: "none",
									}}
									dropdownStyle={{
										width: "300px",
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label='Service Center/Company Name'
									variant='outlined'
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label='Role/Designation'
									variant='outlined'
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<Button
									type='submit'
									variant='contained'
									color='primary'
									size='large'
									fullWidth
									sx={{
										mt: 2,
										py: 1.5,
										fontSize: "1.1rem",
									}}
								>
									Submit
								</Button>
							</Grid>
						</Grid>
					</Box>
				</motion.div>
			</Container>
		</Box>
	);
};

export default RegistrationForm;
