import React from "react";
import { Box, Typography, Button, Grid, Container } from "@mui/material";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import app from "./images/hero.svg";
import {
	Tv,
	Building2,
	Briefcase,
	Zap,
	Cat,
	Car,
	CarFront,
	MonitorSmartphone,
	Brain,
} from "lucide-react";
import { scrollToRegister } from "./utils/scrollUtils";
const Hero = () => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				minHeight: "100vh",
				pt: "64px", // Add padding top to account for the AppBar
				background: `linear-gradient(135deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
				color: "white",
				position: "relative",
				overflow: "hidden",
			}}
		>
			<Container maxWidth='xl'>
				<Grid
					container
					spacing={4}
					alignItems='center'
					sx={{ minHeight: "100vh" }}
				>
					{/* Left Content */}
					<Grid item xs={12} md={6}>
						<motion.div
							initial={{ opacity: 0, x: -20 }}
							animate={{ opacity: 1, x: 0 }}
							transition={{ duration: 0.8 }}
						>
							<Typography
								variant='h1'
								sx={{
									fontSize: { xs: "2.5rem", md: "3.5rem", lg: "4rem" },
									fontWeight: 700,
									mb: 2,
								}}
							>
								AI-powered JIT Procurement
							</Typography>
							<Typography
								variant='h5'
								sx={{
									mb: 4,
									opacity: 0.9,
									maxWidth: "600px",
									lineHeight: 1.5,
								}}
							>
								Instant, AI-generated guaranteed quotes for Consumer
								Electronics/Automobile spares from curated suppliers.
							</Typography>
							<Typography
								variant='h5'
								sx={{
									mb: 4,
									opacity: 0.9,
									maxWidth: "600px",
									lineHeight: 1.5,
								}}
							>
								Close deals faster & reliably, increase revenue by 7X. And, its
								Free!
							</Typography>
							<motion.div
								whileHover={{ scale: 1.05 }}
								whileTap={{ scale: 0.95 }}
							>
								<Button
									variant='contained'
									size='large'
									onClick={scrollToRegister}
									sx={{
										bgcolor: "white",
										color: theme.palette.primary.main,
										px: 4,
										py: 1.5,
										fontSize: "1.1rem",
										"&:hover": {
											bgcolor: "rgba(255, 255, 255, 0.9)",
										},
									}}
								>
									Get a Quote Now
								</Button>
							</motion.div>
						</motion.div>
					</Grid>

					{/* Right Content */}
					<Grid item xs={12} md={6}>
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.8, delay: 0.2 }}
							style={{ position: "relative" }}
						>
							<Box
								sx={{ position: "relative", width: "100%", height: "600px" }}
							>
								{/* Main Dashboard Image */}

								<div
									style={{
										width: 600,
										height: "auto",
										alignItems: "center",
										justifyContent: "center",
										display: "flex",
									}}
								>
									<img
										src={app}
										alt='Jumbuda Platform Dashboard'
										width={"100%"}
										height={"100%"}
									/>
								</div>

								{/* Decorative Elements */}
								<Box
									sx={{
										position: "absolute",
										top: "20%",
										right: "10%",
										width: "200px",
										height: "200px",
										bgcolor: "rgba(255,255,255,0.1)",
										borderRadius: "20px",
										transform: "rotate(15deg)",
										zIndex: 0,
									}}
								/>
								<Box
									sx={{
										position: "absolute",
										bottom: "10%",
										left: "5%",
										width: "150px",
										height: "150px",
										bgcolor: "rgba(255,255,255,0.1)",
										borderRadius: "20px",
										transform: "rotate(-10deg)",
										zIndex: 0,
									}}
								/>
							</Box>
						</motion.div>
					</Grid>
				</Grid>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						gap: { xs: 4, md: 10 },
						mt: 1,
						width: "100%",
						pb: 5,
					}}
				>
					<MonitorSmartphone size={48} color='white' />
					<Tv size={48} color='white' />
					<CarFront size={48} color='white' />
					<Brain size={48} color='white' />
					<Zap size={48} color='white' />
				</Box>
			</Container>
		</Box>
	);
};

export default Hero;
