const { default: jambuda } = require("./core");

const queryRules = { getQueryExipryRule };

function getQueryExipryRule(name) {
	return new Promise((resolve, reject) => {
		jambuda.get(`/api/query-rules?name=${name}`).then((result) => {
			return resolve({ rule: result.data });
		});
	});
}
export default queryRules;
